import styled from 'styled-components'
import { ContainerMain } from '../Container/index.style'

export const PageNotFoundMain = styled.div`
  ${ContainerMain} {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
  }
`
