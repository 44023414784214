import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import PageNotFound from '../components/PageNotFound'

const NotFoundPage = ({ data }) => {
  const { title, returnToHomeText } = data.contentful404

  return (
    <>
      <Seo title="404" description="Page not found" />

      <PageNotFound title={title} text={returnToHomeText} />
    </>
  )
}

export const pageQuery = graphql`
  query {
    contentful404 {
      title
      returnToHomeText
    }
  }
`

export default NotFoundPage
