import React from 'react'
import PropTypes from 'prop-types'
import { PageNotFoundMain } from './index.style'
import ScrollSection from '../ScrollSection'
import Container from '../Container'
import Spacer from '../Spacer'
import Cta from '../Cta'
import { Heading1 } from '../TextStyles'

const PageNotFound = ({ title, text }) => (
  <ScrollSection>
    <PageNotFoundMain>
      <Container>
        <Heading1 as="h2">{title}</Heading1>

        <Spacer size={[16, 24]} />

        <Cta to="/" label={text} />
      </Container>
    </PageNotFoundMain>
  </ScrollSection>
)

PageNotFound.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default PageNotFound
